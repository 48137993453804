<template>
    <van-action-sheet v-model="show" :title="title" @close="$emit('close_time')">
        <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirm"
            @cancel="$emit('close_time')"
        />
    </van-action-sheet>
</template>

<script>
import { formatTime } from '@/assets/js/common.js'

export default {
    name:'',
    data(){
        return {
            show: false,
            currentDate: new Date(),
            minDate: new Date(2022, 0, 1),
            maxDate: new Date(2030, 11, 31),
            time_date: ''
        }
    },
    computed:{},
    methods:{
        confirm(time) {
            this.$emit('submit_time',formatTime(time))
        }
    },
    filters:{},
    props:{
        title: {
            type: String,
            default() {
                return '标题'
            }
        },
        fshow: {
            type: Boolean,
            default() {
                return false
            }
        },
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        fshow(newVal) {
            this.show = newVal
        },
    }
}
</script>

<style>
.content {
    padding: 16px 16px 160px;
}
</style>